// @flow strict
import React from 'react';
import moment from 'moment';
import styles from './Meta.module.scss';

type Props = {
  date: string,
  readingTimeText: string
};

const Meta = ({ date, readingTimeText }: Props) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']}>{moment(date).format('MMMM D, YYYY')} • {readingTimeText}</p>
  </div>
);

export default Meta;
