// @flow strict
import React from 'react';
import styles from './Content.module.scss';
import Meta from '../../Post/Meta';

type Props = {
  body: string,
  title: string,
  date: string,
  readingTimeText: string
};

const Content = ({ body, title, date, readingTimeText }: Props) => (
  <div className={styles['content']}>
    <div className={styles['content__title']}>{title}</div>
    <Meta date={date} readingTimeText={readingTimeText} />
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
